import React, { ReactNode } from "react"
import {
  MainWrapper,
  Wrapper,
  FatherOfMainWrapper,
  MainWrapperMobile,
  HeaderMobile,
  WrapperMobile,
} from "./Layout.style"
import { Header } from "./../Header"
import { Footer } from "./../Footer"
import { Media } from "gatsby-plugin-fresnel"
import { ErrorBoundary } from "../ErrorBoundary"

interface Props {
  className?: string
  children: ReactNode
}

if (typeof window !== "undefined") {
  var body = document.querySelector("body")
  if (body) {
    body.style.margin = "0"
    body.style.overflowX = "hidden"
    body.style.overflowY = "auto"
  }
}

export function LayoutView({ className, children }: Props) {
  return (
    <ErrorBoundary>
      <FatherOfMainWrapper>
        <Media lessThan="md">
          <MainWrapperMobile className={className}>
            <Header></Header>
            <HeaderMobile>
              <WrapperMobile>{children}</WrapperMobile>
            </HeaderMobile>
            <Footer></Footer>
          </MainWrapperMobile>
        </Media>
        <Media greaterThanOrEqual="md">
          <MainWrapper className={className}>
            <Header></Header>
            <Wrapper>{children}</Wrapper>
            <Footer></Footer>
          </MainWrapper>
        </Media>
      </FatherOfMainWrapper>
    </ErrorBoundary>
  )
}
